import React from 'react'
import cx from 'classnames'
import { string, bool, func, oneOfType, number } from 'prop-types'

import { THEMES } from '../constants'

// hoc
import withPalette from '@hoc/withPalette'

// styles
import styles from './_.module.scss'

export const Radio = ({
  id,
  name,
  checked,
  label,
  palette,
  className,
  labelClassName,
  onChange,
  value,
  defaultValue,
  disabled,
  hasBottonPadding
}) => {
  const paletteSelected = `radioInput${palette
    .charAt(0)
    .toUpperCase()}${palette.slice(1)}`
  return (
    <div
      className={cx('di-f', 'alit-c', { 'pab-2': hasBottonPadding }, className)}
    >
      <input
        id={id}
        className={cx(
          'di-f bora-round',
          'cu-p',
          styles.radioInput,
          styles[paletteSelected]
        )}
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <label
        className={cx('di-f', 'pal-2', 'cu-p', labelClassName)}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

Radio.propTypes = {
  id: string,
  name: string,
  checked: oneOfType([bool, number]),
  label: string,
  className: string,
  labelClassName: string,
  palette: string,
  value: oneOfType([string, number]),
  defaultValue: oneOfType([string, number]),
  hasBottonPadding: bool,
  disabled: bool,
  onChange: func
}

Radio.defaultProps = {
  id: '',
  name: '',
  checked: false,
  label: '',
  className: '',
  labelClassName: '',
  value: undefined,
  defaultValue: undefined,
  palette: THEMES.primary,
  hasBottonPadding: true,
  disabled: false,
  onChange: () => null
}

export default withPalette(Radio)
