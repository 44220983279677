import React from 'react'
import cx from 'classnames'
import { string, array, bool, any, func } from 'prop-types'

// components
import Handler from '@compositions/BusinessUnitsDropdown/components/Handler'
import Dropdown from '@components/Dropdown'
import Form from '@components/Form'

// styles
import styles from './_.module.scss'

// local components
const MessageWrapper = props => (
  <div className="pa-2 fosi-1">{props.children}</div>
)

MessageWrapper.propTypes = {
  children: any
}

MessageWrapper.defaultProps = {
  children: null
}

const Select = ({
  label,
  active,
  options,
  isRequired,
  onChange,
  requestStatus,
  requestError
}) => {
  function handleOptionChange(selected, callback) {
    onChange(selected)
    callback(false)
  }
  return (
    <>
      <p className="fosi-1">
        {label}
        {isRequired && <small className="co-orange-3"> (required)</small>}
      </p>
      <Dropdown
        className="mab-2"
        hideOnEventName="mousedown"
        //   onEventOutside={() =>
        //     onChange()
        //   }
        handler={isOpen =>
          requestStatus ? (
            'Loading...'
          ) : (
            <Handler
              className={cx('wi-100', 'fosi-2', styles.label)}
              isOpen={isOpen}
              label={active}
            />
          )
        }
        isOpen={false}
        cardStyle={{ overflowY: 'auto' }}
      >
        {setIsOpen => (
          <Form className={cx('di-f', 'fldi-c', styles.wrapper)}>
            <ul>
              {options.map((item, index) => (
                <li key={index} className="baco-white">
                  <div
                    className={cx(
                      'di-f',
                      'alit-c',
                      'cu-p',
                      'juco-sb',
                      'pax-3',
                      'pay-2',
                      'fosi-2',
                      styles.item
                    )}
                    onClick={() => handleOptionChange(item, setIsOpen)}
                  >
                    {item.title || item.value}
                  </div>
                </li>
              ))}
            </ul>
            {requestStatus && (
              <MessageWrapper>Loading {label}...</MessageWrapper>
            )}
          </Form>
        )}
      </Dropdown>
      {requestError && <MessageWrapper>{requestError.message}</MessageWrapper>}
    </>
  )
}

Select.propTypes = {
  label: string,
  active: string,
  options: array,
  isRequired: bool,
  requestStatus: bool,
  requestError: any,
  onChange: func
}

Select.defaultProps = {
  label: '__unset__',
  active: '__unset__',
  options: [],
  isRequired: false,
  requestStatus: false,
  requestError: {},
  onChange: () => null
}

export default Select
