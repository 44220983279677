import React, { Component } from 'react'
import { any, func, number, object, bool } from 'prop-types'
import cx from 'classnames'

// components
import Checkbox, { EMPTY, FILLED } from '@components/Checkbox'
import Radio from '@components/Radio'

// styles
import styles from './_.module.scss'

const PARTITION_DICT = [null, null, 'Division', 'Region']
// utils
const taxonomyMap = {
  1: 'Organization',
  2: 'Division',
  3: 'Region',
  4: 'Community'
}

const Header = ({ node, status, isLeaf, isMultiple, onChange }) => (
  <div
    className={cx(
      'di-f',
      'alit-c',
      'juco-sb',
      'pal-3',
      styles.header,
      styles['headerLevel' + node.level],
      styles['headerTaxonomy' + taxonomyMap[node.level]],
      { [styles.headerTaxonomyLeaf]: isLeaf }
    )}
  >
    <div className="flba-a" />

    <div className={cx('par-3', 'wi-100')}>
      {!isMultiple && isLeaf ? (
        <Radio
          id={`community-${node.id}`}
          name="communities"
          checked={status}
          label={node.name}
          onChange={evt => onChange(evt.target.checked)}
          labelClassName="fosi-2"
          hasBottonPadding={false}
          defaultValue={node.id}
        />
      ) : isMultiple ? (
        <Checkbox
          titleClassName={cx(
            'par-3',
            styles.title,
            styles['titleLevel' + node.level],
            styles['titleStatus' + status],
            { [styles.titleTaxonomyLeaf]: isLeaf }
          )}
          title={node.name}
          name="communities[]"
          value={status}
          onClick={onChange}
          hasTextColor
        />
      ) : (
        <h5
          className={cx(
            'par-3',
            styles.title,
            styles['titleLevel' + node.level],
            styles['titleStatus' + status],
            { [styles.titleTaxonomyLeaf]: isLeaf }
          )}
        >
          {node.name} {PARTITION_DICT[node.level]}
        </h5>
      )}
    </div>
  </div>
)

Header.propTypes = {
  isLeaf: bool,
  node: object,
  status: number,
  isMultiple: bool,
  onChange: func
}

Header.defaultProps = {
  isLeaf: false,
  isMultiple: false,
  node: {},
  status: EMPTY,
  onChange: () => null
}

// accessors
const STATE_STATUS = 'isActive'

export default class OptionPolyRec extends Component {
  state = {
    [STATE_STATUS]: EMPTY
  }

  static getDerivedStateFromProps = props => {
    const { node, selectedBranchesIds, selectedLeafsIds } = props
    const selectedBranchesIdsAsArray = Array.from(selectedBranchesIds)
    const selectedLeafsIdsAsArray = Array.from(selectedLeafsIds)
    const ids = new Set([
      ...selectedBranchesIdsAsArray,
      ...selectedLeafsIdsAsArray
    ])
    return {
      [STATE_STATUS]: ids.has(String(node.id)) ? FILLED : EMPTY
    }
  }

  render() {
    const {
      parent,
      node,
      selectedBranchesIds,
      selectedLeafsIds,
      onChange,
      depth,
      isMultiple
    } = this.props
    const nodes = node.nodes || []
    const { [STATE_STATUS]: status } = this.state
    const isEnoughDepth = depth >= node.level
    const isLeaf = node.level === depth

    return isEnoughDepth ? (
      <ul>
        {node.level ? (
          <Header
            node={node}
            status={status}
            onChange={isChecked => onChange(isChecked, parent, node)}
            isLeaf={isLeaf}
            isMultiple={isMultiple}
          />
        ) : null}

        {nodes.map((item, index) => (
          <li key={item.id} className={cx(styles['wrapper' + node.level])}>
            <OptionPolyRec
              depth={depth}
              parent={node}
              node={item}
              selectedBranchesIds={selectedBranchesIds}
              selectedLeafsIds={selectedLeafsIds}
              onChange={onChange}
              isMultiple={isMultiple}
            />
          </li>
        ))}
      </ul>
    ) : null
  }
}

OptionPolyRec.propTypes = {
  depth: number,
  node: object,
  parent: any,
  selectedBranchesIds: any,
  selectedLeafsIds: any,
  isMultiple: bool,
  onChange: func
}

OptionPolyRec.defaultProps = {
  depth: 4,
  parent: null,
  node: {},
  isMultiple: false,
  selectedLeafsIds: new Set([]),
  selectedBranchesIds: new Set([]),
  onChange: () => null
}
