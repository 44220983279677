import React from 'react'
import { any } from 'prop-types'

// compositions
import Layout from '@compositions/Layout'
import Reports from '@compositions/Reports'

const breadcrumbs = [{ key: 'reports', text: 'Reports', url: '/reports' }]

const ReportsPage = ({ location }) => (
  <Layout breadcrumbs={breadcrumbs} flagName="reports">
    <Reports location={location} />
  </Layout>
)

ReportsPage.propTypes = {
  location: any
}

ReportsPage.defaultProps = {
  location: {}
}

export default ReportsPage
