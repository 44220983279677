import React from 'react'
import cx from 'classnames'
import { number, string, node, bool } from 'prop-types'

// components
import Step from '../Step'

// styles
import styles from './_.module.scss'

const Column = ({ step, current, title, isFullWidth, children }) => (
  <div className={cx('di-f', 'fldi-c', styles.step)}>
    {current >= step ? (
      <>
        <div className="di-f alit-c mab-3">
          <Step>{step}</Step>
          <h2 className="mal-2 co-black">{title}</h2>
        </div>
        <div className="di-f fldi-c max-4 pax-4">{children}</div>
      </>
    ) : null}
  </div>
)

Column.propTypes = {
  step: number.isRequired,
  current: number.isRequired,
  title: string.isRequired,
  isFullWidth: bool,
  children: node
}

Column.defaultProps = {
  isFullWidth: true,
  children: null
}

export default Column
