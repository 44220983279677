import React from 'react'
import cx from 'classnames'
import { any, array, bool, func, string } from 'prop-types'

// components
import BusinessUnits from '@components/BusinessUnits'

// styles
import styles from './_.module.scss'

const Dataset = ({
  label,
  setSelectedBranches,
  setSelectedLeafsIds,
  onEventOutside,
  selectedLeafsIdsAsArray,
  selectedBranchesIdsAsArray,
  selectedLeafsIds,
  selectedBranchesIds,
  responseRequestError,
  responseRequestStatus,
  data,
  isRequired,
  isMultiple
}) => {
  return (
    <>
      <p className="fosi-1">
        {label}
        {isRequired && <small className="co-orange-3"> (required)</small>}
      </p>
      <div
        className={cx('bowi-1', 'bost-s', 'bora-1', 'mab-2', styles.container)}
      >
        <BusinessUnits
          onChange={({ branches, leafsIds, node }) => {
            if (isMultiple) {
              setSelectedLeafsIds(leafsIds)
            } else {
              setSelectedLeafsIds(new Set([String(node.id)]))
            }
            if (branches) {
              setSelectedBranches(branches)
            }
          }}
          onEventOutside={onEventOutside}
          selectedLeafsIdsAsArray={selectedLeafsIdsAsArray}
          selectedBranchesIdsAsArray={selectedBranchesIdsAsArray}
          shouldHideLabel={true}
          label="All Communities"
          responseRequestStatus={responseRequestStatus}
          responseRequestError={responseRequestError}
          depth={4}
          selectedBranchesIds={selectedBranchesIds}
          selectedLeafsIds={selectedLeafsIds}
          results={data}
          selectedBranchesIdsPartially={[]}
          isMultiple={isMultiple}
        />
      </div>
    </>
  )
}

Dataset.propTypes = {
  label: string,
  selectedLeafsIdsAsArray: array,
  selectedBranchesIdsAsArray: array,
  selectedLeafsIds: any,
  selectedBranchesIds: any,
  responseRequestError: any,
  responseRequestStatus: bool,
  isRequired: bool,
  isMultiple: bool,
  data: array,
  setSelectedBranches: func,
  setSelectedLeafsIds: func,
  onEventOutside: func
}

Dataset.defaultProps = {
  label: '',
  selectedLeafsIdsAsArray: [],
  selectedLeafsIds: [],
  selectedBranchesIdsAsArray: [],
  selectedBranchesIds: [],
  responseRequestError: null,
  responseRequestStatus: false,
  isRequired: false,
  isMultiple: false,
  data: [],
  setSelectedBranches: () => null,
  setSelectedLeafsIds: () => null,
  onEventOutside: () => null
}

export default Dataset
