import React, { useState } from 'react'
import { shape, any, func, bool } from 'prop-types'

// components
import TimePeriodDropdown from '@compositions/TimePeriodDropdown'

const DateFilter = ({ params, isRequired, onChange }) => {
  const [label, setLabel] = useState(null)
  return (
    <>
      <p className="fosi-1">
        Date Range
      </p>
      <TimePeriodDropdown
        className="wi-100 mab-2"
        label={label}
        onChange={data => {
          setLabel(data.label)
          onChange(data)
        }}
        initialStartDate={params.startDate}
        initialEndDate={params.endDate}
      />
    </>
  )
}

DateFilter.propTypes = {
  params: shape({ startDate: any, endDate: any }),
  isRequired: bool,
  onChange: func
}

DateFilter.defaultProps = {
  params: {},
  isRequired: false,
  onChange: () => null
}

export default DateFilter
