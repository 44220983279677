import React from 'react'
import cx from 'classnames'
import { array, func, string, any } from 'prop-types'

// components
import Handler from '@compositions/BusinessUnitsDropdown/components/Handler'
import Dropdown from '@components/Dropdown'
import Form from '@components/Form'
import TextField from '@components/TextField'

// modules
import datejs from '@modules/datejs'

// styles
import styles from './_.module.scss'

const TimePeriodDropdown = ({
  preffix,
  label,
  timePeriods,
  onChange,
  initialStartDate,
  initialEndDate,
  className
}) => {
  const selected = label
    ? { label }
    : timePeriods.length > 0
    ? timePeriods[0]
    : { label: 'Please select period' }

  return (
    <Dropdown
      id={`${preffix}dropdown`}
      handler={isOpen => <Handler id={`${preffix}dropdown-openbutton`} isOpen={isOpen} label={selected.label} className={className} />}
      isOpen={false}
    >
      {setIsOpen => (
        <div className={cx('di-f', 'fldi-c', styles.timePeriodsContent)}>
          <Form>
            <div className={cx('fosi-2', 'pax-3', 'mat-2', 'pay-2')}>
              <TextField.DateRange
                startDateId={`${preffix}dropdown-option-daterange-start`}
                endDateId={`${preffix}dropdown-option-daterange-end`}
                label={"Custom Date Range"}
                onChange={(startDate, endDate) => {
                  onChange({ label: 'Date range selected', startDate, endDate })
                }}
                startDate={initialStartDate ? new Date(initialStartDate) : null}
                endDate={initialEndDate ? new Date(initialEndDate) : null}
              />
            </div>

            {timePeriods.map((item, index) => (
              <div
                key={index}
                id={`${preffix}dropdown-option-${index + 1}`}
                className={cx(
                  'fosi-2',
                  'pax-3',
                  'mat-2',
                  'cu-p',
                  'pay-2',
                  'ou-n',
                  { 'co-primary-3': item.label === selected.label }
                )}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setIsOpen(false)
                  onChange({ label: item.label, startDate: item.dateTo })
                }}
              >
                {item.label}
              </div>
            ))}
          </Form>
        </div>
      )}
    </Dropdown>
  )
}

TimePeriodDropdown.propTypes = {
  preffix: string,
  initialStartDate: any,
  initialEndDate: any,
  label: string,
  timePeriods: array,
  className: string,
  onChange: func
}

TimePeriodDropdown.defaultProps = {
  preffix: null,
  label: null,
  initialStartDate: null,
  initialEndDate: null,
  className: null,
  timePeriods: [
    {
      label: 'Last 12 Months',
      dateTo: datejs()
        .subtract(12, 'month')
        .startOf('day')
    },
    {
      label: 'Last 30 Days',
      dateTo: datejs()
        .subtract(30, 'day')
        .startOf('day')
    },
    {
      label: 'This Year',
      dateTo: datejs()
        .set('month', 0)
        .set('date', 1)
        .startOf('day')
    },
    {
      label: 'This Month',
      dateTo: datejs()
        .set('date', 1)
        .startOf('day')
    }
  ],
  onChange: () => null
}

export default TimePeriodDropdown
