import React from 'react'
import cx from 'classnames'
import { bool, number, string } from 'prop-types'

//components
import Button from '@components/Button'
import Icon from '@components/Icon'

// modules
import { THEMES } from '@components/constants'

// styles
import styles from './_.module.scss'

const Handler = ({
  id,
  total,
  counter,
  label,
  multipleLabel,
  isOpen,
  className
}) => (
  <Button
    id={id}
    className={cx(
      'fosi-2',
      'pay-2',
      'di-f',
      'juco-sa',
      styles.handler,
      className
    )}
    palette={total ? THEMES.gray : THEMES.white}
  >
    <span
      className={cx(
        'ov-h',
        'teov-e',
        'whsp-nw',
        'teal-l',
        total ? 'par-5' : 'par-3',
        'po-r',
        {
          'mar-4': total
        },
        'qa-leads-button__action--showfilter-communities',
        styles.text
      )}
    >
      {total ? `${multipleLabel} (${total})` : label}
    </span>

    <div className={styles.div} />

    <Icon
      name="chevron-down"
      className={cx(styles.icon, {
        [styles.arrowDown]: isOpen
      })}
      size={10}
    />
  </Button>
)

Handler.propTypes = {
  id: string,
  total: number,
  counter: number,
  isOpen: bool,
  label: string,
  multipleLabel: string,
  className: string
}

Handler.defaultProps = {
  id: null,
  total: 0,
  counter: 0,
  isOpen: false,
  label: '__unset__',
  multipleLabel: null,
  className: null
}

export default Handler
