import React from 'react'
import { node } from 'prop-types'

// components
import Chart from '@components/Chart'

// globals
const circleX = 21
const circleY = circleX
const circleRadius = 15.91549430918954

const Step = ({ children }) => (
  <Chart size={45}>
    <svg width="100%" height="100%" viewBox="4 4 34 34">
      <circle
        cx={circleX}
        cy={circleY}
        r={circleRadius}
        fill="#10C8BE"
        strokeWidth={5}
      />
      <text
        textAnchor="middle"
        x="63%"
        y="65%"
        dominantBaseline="middle"
        fill="white"
      >
        {children}
      </text>
    </svg>
  </Chart>
)

Step.propTypes = {
  children: node.isRequired
}

export default Step