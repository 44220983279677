import React from 'react'
import cx from 'classnames'
import { any, array, bool, object, number, func } from 'prop-types'

// components
import Form from '@components/Form'
import OptionPolyRec from './components/OptionPolyRec'

// modules
import Optimizer from '@modules/optimizer'
import { handleBranchChange } from './modules/helpers'

// styles
import styles from './_.module.scss'

// local components
const MessageWrapper = props => (
  <div className="pa-2 fosi-1">{props.children}</div>
)

MessageWrapper.propTypes = {
  children: any
}

MessageWrapper.defaultProps = {
  children: null
}

// helpers
const handleLeafChange = ({
  isChecked,
  parent,
  node,
  selectedLeafsIds,
  selectedBranchesIdsPartially
}) => {
  const leafs = new Set(selectedLeafsIds)
  isChecked ? leafs.add(String(node.id)) : leafs.delete(String(node.id))

  return leafs
}

const BusinessUnits = ({ onChange, ...props }) => (
  <div className={cx('di-f', 'fldi-c', styles.communityFiltersDivisions)}>
    <Form>
      {props.responseRequestStatus && (
        <MessageWrapper>Loading {props.label}...</MessageWrapper>
      )}

      {props.responseRequestError && (
        <MessageWrapper>An error occured</MessageWrapper>
      )}

      {!props.responseRequestStatus && !props.responseRequestError ? (
        <OptionPolyRec
          isMultiple={props.isMultiple}
          depth={props.depth}
          selectedBranchesIds={props.selectedBranchesIds}
          selectedLeafsIds={props.selectedLeafsIds}
          node={
            props.results && props.results.length > 0 ? props.results[0] : {}
          }
          onChange={Optimizer.debounce(
            (isChecked, parent, node) => {
              const isBranch = node.level < props.depth
              const handler = isBranch
                ? () => {
                    const { branches, leafs } = handleBranchChange({
                      isChecked,
                      node,
                      selectedBranchesIds: props.selectedBranchesIds,
                      selectedLeafsIds: props.selectedLeafsIds,
                      depth: props.depth
                    })

                    onChange({
                      node,
                      isChecked,
                      branches,
                      leafsIds: leafs
                    })
                  }
                : () => {
                    const leafsIds = handleLeafChange({
                      isChecked,
                      parent,
                      node,
                      selectedLeafsIds: props.selectedLeafsIds,
                      selectedBranchesIdsPartially:
                        props.selectedBranchesIdsPartially
                    })

                    onChange({
                      node,
                      leafsIds
                    })
                  }

              handler()
            },
            10,
            true
          )}
        />
      ) : null}
    </Form>
  </div>
)

BusinessUnits.propTypes = {
  selectedLeafsIdsAsArray: array,
  selectedBranchesIdsAsArray: array,
  label: any,
  responseRequestError: any,
  responseRequestStatus: bool,
  selectedBranchesIds: object,
  selectedLeafsIds: object,
  depth: number,
  results: array,
  selectedBranchesIdsPartially: array,
  isMultiple: bool,
  onChange: func,
  onEventOutside: func
}

BusinessUnits.defaultProps = {
  onChange: () => null,
  onEventOutside: () => null,
  selectedLeafsIdsAsArray: [],
  selectedBranchesIdsAsArray: [],
  label: null,
  responseRequestError: null,
  responseRequestStatus: false,
  selectedLeafsIds: [],
  selectedBranchesIds: [],
  depth: 4,
  results: [],
  selectedBranchesIdsPartially: [],
  isMultiple: true
}

export default BusinessUnits
