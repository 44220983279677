import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { any } from 'prop-types'

// modules
import Url, { ARRAY_COMMA } from '@modules/url'
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'
import datejs from '@modules/datejs'
import Env from '@modules/env'

// components
import Button from '@components/Button'
import Column from './components/Column'
import DateFilter from './components/DateFilter'
import Dataset from './components/Dataset'
import Select from './components/Select'
import { getLeafNodesIds } from '@components/BusinessUnits/modules/helpers'

// styles
import styles from './_.module.scss'

const STEP_2_FILTERS = 2
const STEP_3_RUN = 3

const COMMUNITY_LEVEL = 4

const DATE_FORMAT = 'MM/DD/YYYY'

const communitiesCollection = new Collection(
  REST_API_ENDPOINTS.businessUnits,
  API_VERSION
)
const reportsCollection = new Collection(REST_API_ENDPOINTS.reports, API_VERSION)

const getDefaultValue = (dataMap, filter) => {
  let value = dataMap[filter.name] || filter.value
  if (!value) {
    const options = filter.options || []
    value = options && options.length > 0 ? options[0].value : null
  }
  return value
}

const Reports = ({ location }) => {
  const { search } = location || {}
  const params = Url.parseSearch(search)

  const [selectedReport, setSelectedReport] = useState({})
  const [model, setModel] = useState({})
  const [label, setLabel] = useState('Select report')

  const [selectedLeafsIds, setSelectedLeafsIds] = useState(new Set())
  const [selectedBranches, setSelectedBranches] = useState(new Set())

  const [step, setStep] = useState(1)

  const [reportsRequestStatus, setReportsRequestStatus] = useState(false)
  const [reportsRequestError, setReportsRequestError] = useState(null)
  const [reports, setReports] = useState([])

  const [communitiesRequestStatus, setCommunitiesRequestStatus] = useState(
    false
  )
  const [communitiesRequestError, setCommunitiesRequestError] = useState(null)
  const [communities, setCommunities] = useState([])
  const [organization, setOrganization] = useState({})

  const [formIsInvalid, setFormIsInvalid] = useState(false)

  const selectedBranchesIdsAsArray = Array.from(selectedBranches)
  const selectedLeafsIdsAsArray = Array.from(selectedLeafsIds)

  function handleOnChangeReport(report) {
    setSelectedReport(report)
    setLabel(report.title)
    if (report.filters && report.filters.length > 0) {
      const defaultModel = {}
      const valueByName = {
        org_id: organization.id,
        OrgName: organization.name,
        PropertyID: organization.defaultCommunityId,
        Properties: organization.defaultCommunityId,
        StartDate: datejs()
          .subtract(12, 'month')
          .format(DATE_FORMAT),
        EndDate: datejs().format(DATE_FORMAT)
      }
      report.filters.forEach(filter => {
        const value = getDefaultValue(valueByName, filter)
        defaultModel[filter.name] = value
        if (filter.name === 'StartDate' && model[filter.name]) {
          defaultModel[filter.name] = datejs(model[filter.name]).format(
            DATE_FORMAT
          )
        }
        if (
          (filter.name === 'PropertyID' || filter.name === 'Properties') &&
          organization &&
          organization.defaultCommunityId
        ) {
          setSelectedLeafsIds(
            new Set([String(organization.defaultCommunityId)])
          )
        }
      })
      updateModel(defaultModel, true)
    }
    setStep(STEP_2_FILTERS)
  }
  function updateModel(changes = {}, reset = false) {
    if (changes !== null){
      setModel(prevState => {
        if (reset) return changes

        const combinedState = { ...prevState, ...changes }

        if(Object.values(changes)[0] !== 'None'){
          if(changes.hasOwnProperty('SortBy')) return { ...combinedState, GroupBy: 'None' }
          if(changes.hasOwnProperty('GroupBy')) return { ...combinedState, SortBy: 'None' }
        }

        return { ...combinedState }
      })
    }
  }
  function handleFormSubmit(reportName, formModel) {
    return () => {
      const baseUrl = process.env['GATSBY_SSRS_URL']
      const url = `${baseUrl}${reportName}${Url.stringifySearch(formModel, {
        arrayFormat: ARRAY_COMMA
      })}`
      Env.isClient(() => {
        Env.getWindow().open(url, '_blank')
      })
    }
  }
  useEffect(() => {
    const config = {
      API_VERSION
    }
    setReportsRequestStatus(true)
    reportsCollection.readSigned(config, (err, res) => {
      setReportsRequestStatus(false)
      if (err) return setReportsRequestError(err)
      setReportsRequestError(null)
      const results = (res && res.data && res.data.results) || []
      setReports(results)
    })
    setCommunitiesRequestStatus(true)
    communitiesCollection.readSigned(config, (err, res) => {
      setCommunitiesRequestStatus(false)
      if (err) return setCommunitiesRequestError(err)
      setCommunitiesRequestError(null)
      const communities = (res && res.data && res.data.results) || []
      setCommunities(communities)
      const org =
        communities.length === 0
          ? {}
          : (communities[0].nodes && communities[0].nodes[0]) || {}
      const communitiesList = Array.from(
        getLeafNodesIds(communities, COMMUNITY_LEVEL)
      )
      const defaultCommunityId =
        communitiesList.length > 0 ? communitiesList[0] : {}
      setOrganization({
        id: org.id,
        name: org.name,
        defaultCommunityId
      })
    })
  }, [])
  useEffect(() => {
    const filters = (selectedReport && selectedReport.filters) || []
    if (filters.length > 0) {
      let isInvalid = false
      let requiresPropertyId = false
      filters.forEach(filter => {
        if (filter.type !== 'hidden') {
          const modelValue = model[filter.name]
          if (
            filter.isRequired &&
            filter.name !== 'EndDate' &&
            (typeof modelValue === 'undefined' ||
              !modelValue ||
              (Array.isArray(modelValue) && modelValue.length === 0))
          )
            isInvalid = true
        }
      })
      if (requiresPropertyId && selectedLeafsIdsAsArray.length === 0) {
        isInvalid = true
      }
      setFormIsInvalid(isInvalid)
      if (step === STEP_2_FILTERS) {
        setStep(STEP_3_RUN)
      }
    }
  }, [model, step, selectedReport, selectedLeafsIdsAsArray])
  const filters = (selectedReport && selectedReport.filters) || []
  return (
    <div className={cx('di-f', 'mat-4', styles.report)}>
      <Column step={1} title="Select" current={step}>
        <h3 className="fowe-b mab-2">Choose a report type.</h3>
        <Select
          label="Report Types"
          requestStatus={reportsRequestStatus}
          requestError={reportsRequestError}
          active={label}
          options={reports}
          onChange={report => handleOnChangeReport(report)}
        />
        <p className="fosi-1">{selectedReport.description}</p>
      </Column>
      <Column step={STEP_2_FILTERS} title="Filter" current={step}>
        {!reportsRequestStatus &&
          !reportsRequestError &&
          filters.map((filter, index) =>
            filter.type === 'date' && filter.name !== 'EndDate' ? (
              <DateFilter
                key={index}
                params={params}
                isRequired={filter.isRequired}
                onChange={({ startDate, endDate }) => {
                  updateModel({
                    [filter.name]: datejs(startDate).format(DATE_FORMAT),
                    EndDate: datejs(endDate).format(DATE_FORMAT)
                  })
                }}
              />
            ) : filter.type === 'communities' ? (
              <Dataset
                key={index}
                {...filter}
                selectedBranchesIdsAsArray={selectedBranchesIdsAsArray}
                selectedBranchesIds={selectedBranches}
                selectedLeafsIdsAsArray={selectedLeafsIdsAsArray}
                selectedLeafsIds={selectedLeafsIds}
                setSelectedBranches={setSelectedBranches}
                setSelectedLeafsIds={ids => {
                  updateModel({ [filter.name]: Array.from(ids) })
                  setSelectedLeafsIds(ids)
                }}
                responseRequestStatus={communitiesRequestStatus}
                responseRequestError={communitiesRequestError}
                data={communities}
              />
            ) : filter.type === 'select' ? (
              <Select
                key={index}
                {...filter}
                active={model[filter.name] || 'None'}
                options={filter.options || []}
                onChange={({ value }) => updateModel({ [filter.name]: value })}
              />
            ) : null
          )}
      </Column>
      <Column
        step={STEP_3_RUN}
        title={'Run & Save'}
        current={step}
        isFullWidth={false}
      >
        <Button
          className="pa-2"
          onClick={handleFormSubmit(selectedReport.name || '', model)}
          disabled={reportsRequestStatus || formIsInvalid}
        >
          <div className="teal-c fosi-1 wi-100">Run Report</div>
        </Button>
      </Column>
    </div>
  )
}

Reports.propTypes = {
  location: any
}

Reports.defaultProps = {
  location: {}
}
export default Reports
